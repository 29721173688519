export default defineNuxtRouteMiddleware((to) => {
  const appStore = useAppStore();
  const recentPagesStore = useRecentPagesStore();

  if (!to.fullPath.includes('/old/')) {
    recentPagesStore.addItem({
      name: appStore.title,
      route: to.path,
      breadcrumbs: appStore.breadcrumbs,
      timestamp: new Date(),
    });
  }
});
